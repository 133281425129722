import React, { Component } from 'react'
import authApi from '../../../api/auth'

const SignInButton = () => {
  return (
    <button
      type="submit"
      style={{
        height: 30,
        backgroundColor: '#ec584b',
        width: 90,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        color: 'white',
        fontSize: 16,
        border: 'none',
      }}
      tabIndex={0}
      onClick={async () => {
        const { url } = await authApi.authUrl()
        window.location.href = url
      }}
      onKeyDown={() => {}}
    >
      Sign In
    </button>
  )
}

export default SignInButton