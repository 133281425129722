import React from 'react'
import DropdownInput from "../DropdownInput";

const DropdownWithTitle = ({title, value, options, onChange, disabled}) => {

  onChange = onChange ? onChange : () => {};

  return (
    <div>
      <h6 className={`text-uppercase font-size-12 mb-2`}>{title}</h6>
      <DropdownInput
        title={title}
        value={value}
        options={options}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
}

export default DropdownWithTitle
