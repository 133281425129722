import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import rolesApi from '../../api/roles'

export function* LOAD_ROLES() {
  yield put({
    type: 'roles/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let roles;
  try {
    roles = yield call(rolesApi.list.bind(rolesApi))
  } catch (err) {
    console.warn(err);
  }

  if (roles) {
    yield put({
      type: 'roles/SET_STATE',
      payload: {
        roles
      },
    })
  }
  yield put({
    type: 'roles/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ROLES, LOAD_ROLES),
    LOAD_ROLES(), // run once on app load to check user auth
  ])
}
