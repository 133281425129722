import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import companies from './companies/reducers'
import menu from './menu/reducers'
import modules from './modules/reducers'
import module_builds from './module_builds/reducers'
import settings from './settings/reducers'
import streaming from './streaming/reducers'
import permissions from './permissions/reducers'
import roles from './roles/reducers'
import user from './user/reducers'
import users from './users/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    companies,
    menu,
    modules,
    module_builds,
    permissions,
    roles,
    settings,
    streaming,
    user,
    users
  })
