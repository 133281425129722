import { all } from 'redux-saga/effects'
import companies from './companies/sagas'
import menu from './menu/sagas'
import modules from './modules/sagas'
import module_builds from './module_builds/sagas'
import settings from './settings/sagas'
import streaming from './streaming/sagas'
import user from './user/sagas'
import users from './users/sagas'
import permissions from './permissions/sagas'
import roles from './roles/sagas'

export default function* rootSaga() {
  yield all([
    companies(),
    menu(),
    modules(),
    module_builds(),
    permissions(),
    roles(),
    settings(),
    streaming(),
    user(),
    users(),
  ])
}
