import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/cleanui/layout/TopBar'
import Breadcrumbs from 'components/cleanui/layout/Breadcrumbs'
import Menu from 'components/cleanui/layout/Menu'
import MenuLeft from 'components/cleanui/layout/Menu/MenuLeft'
import Footer from 'components/cleanui/layout/Footer'

const mapStateToProps = ({ settings, user }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  user,
})

const MainLayout = ({
  children,
  isContentMaxWidth,
  isAppMaxWidth,
  isGrayBackground,
  isSquaredBorders,
  isCardShadow,
  isBorderless,
  isTopbarFixed,
  isGrayTopbar,
  user,
}) => {
  console.log(user)
  return (
    <div
      style={{ height: '100%' }}
      className={classNames({ cui__layout__grayBackground: isGrayBackground })}
    >
      {user.authorized ? (
        <Layout
          style={{ height: '100%' }}
          className={classNames({
            cui__layout__contentMaxWidth: isContentMaxWidth,
            cui__layout__appMaxWidth: isAppMaxWidth,
            cui__layout__grayBackground: isGrayBackground,
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
          })}
        >
          <MenuLeft />
          <Layout style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Layout.Header
              className={classNames('cui__layout__header', {
                cui__layout__fixedHeader: isTopbarFixed,
                cui__layout__headerGray: isGrayTopbar,
              })}
            >
              <TopBar />
            </Layout.Header>
            <Breadcrumbs />
            <Layout.Content style={{ flex: 1, position: 'relative' }}>
              <div style={{ height: '100%'}}>{children}</div>
            </Layout.Content>
            {/*<Layout.Footer>*/}
            {/*  <Footer />*/}
            {/*</Layout.Footer>*/}
          </Layout>
        </Layout>
      ) : null}
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
