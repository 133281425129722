import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import { history, store as reduxStore } from 'index'
import actions from './actions'
import Company from '../../api/Company'

export function* LOAD_COMPANIES() {
  try {
    const companies = yield call(Company.list.bind(Company))
    yield put({
      type: 'companies/SET_STATE',
      payload: {
        companies,
      },
    })
  } catch (error) {
    console.log(error)
    yield put({ type: 'REQUEST_FAILED', payload: { error } })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_COMPANIES, LOAD_COMPANIES), LOAD_COMPANIES()])
}
