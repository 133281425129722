import actions from './actions'

const initialState = {
  id_user: '',
  user_name: '',
  role: '',
  user_email: '',
  user_image: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
  loading: false,
  id_company: '',
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
