import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import modulesApi from '../../api/modules'

export function* LOAD_MODULES() {
  yield put({
    type: 'modules/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let modules;
  try {
    modules = yield call(modulesApi.list.bind(modulesApi))
  } catch (err) {
    console.warn(err);
  }

  if (modules) {
    yield put({
      type: 'modules/SET_STATE',
      payload: {
        modules
      },
    })
  }
  yield put({
    type: 'modules/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_MODULES, LOAD_MODULES),
    LOAD_MODULES(), // run once on app load to check user auth
  ])
}
