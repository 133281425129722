import { all, takeEvery, put, call } from 'redux-saga/effects'
import store from 'store'
import qs from 'qs'
import { history, store as reduxStore } from 'index'
import actions from './actions'
import User from '../../api/User'

export function* LOAD_USERS() {
  try {
    const users = yield call(User.list.bind(User));
    yield put({
      type: 'users/SET_STATE',
      payload: {
        users
      },
    })
  } catch (error) {
    console.log(error)
    yield put({ type: 'REQUEST_FAILED', payload: { error } })
  }
}

export default function* rootSaga() {
  yield all([
    LOAD_USERS(),
    takeEvery(actions.LOAD_USERS, LOAD_USERS)
  ])
}
