import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import loadBalancersApi from '../../api/load_balancers'

export function* LOAD_STREAMING_SERVERS() {
  yield put({
    type: 'streaming/SET_STATE',
    payload: {
      loading: true,
    },
  });
  let response;
  try {
    response = yield call(loadBalancersApi.list.bind(loadBalancersApi));
    if (response) {
      yield put({
        type: 'streaming/SET_STATE',
        payload: {
          loadBalancersList: response
        },
      })
    }
  } catch(err) {
    console.error(err);
  }

  yield put({
    type: 'streaming/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STREAMING_SERVERS, LOAD_STREAMING_SERVERS),
    LOAD_STREAMING_SERVERS(), // run once on app load to check user auth
  ])
}
