import React from 'react'
import style from './style.module.scss'
import {Button, Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";

const DropdownInput = ({all, title, value, options, onChange, disabled}) => {

  onChange = onChange ? onChange : () => {};

  const getLabel = () => {
    return options.filter(obj => obj.value === value).map(obj => obj.label).pop() || title;
  }

  const overlayOptions = (
    <Menu>
      {all && <Menu.Item
        key={'0'}
        onClick={() => onChange(undefined)}
      >
        All
      </Menu.Item>}
      {options && options.map((obj, idx) => (
        <Menu.Item
          key={Number(idx)}
          onClick={() => onChange(obj.value)}
        >
          {obj.label}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown
      overlay={overlayOptions}
      className={'width-100p'}
      disabled={disabled}
    >
      <Button>
        {getLabel()}
        <DownOutlined style={{ verticalAlign: 'center' }} />
      </Button>
    </Dropdown>
  )
}

export default DropdownInput
