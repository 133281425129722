import React, { useState } from 'react'
import { Input, Checkbox } from 'antd'
import Utils from '../../../../helpers/utils'
import PerfectScrollbar from 'react-perfect-scrollbar'
import TextInput from '../general/TextInput'
import moment from 'moment'
import DropdownWithTitle from '../general/DropdownWithTitle'
import { CustomDropzone } from '../general/CustomDropzone'
import User from '../../../../api/User'
import countriesList from 'countries-list'
import SelectBox from '../general/SelectBox';

const EditProfile = props => {
  let selectedUser = props.user
  let roles = props.roles
  let companyOptions = props.companyOptions

  const onChange = props.onChange ? props.onChange : (parameter, value) => {}

  const onRoleChange = roleObj => e => {
    let rolesArray = Utils.clone(selectedUser.roles)
    if (!rolesArray) {
      rolesArray = []
    }
    let checked = e.target.checked
    let rolesArrayTemp = rolesArray.filter(obj => obj.id_role === roleObj.id_role)
    if (rolesArrayTemp.length) {
      roleObj = rolesArrayTemp[0]
      roleObj.add = checked
      roleObj.remove = !checked
    } else {
      roleObj.add = true
      rolesArray.push(roleObj)
    }
    onChange('roles', rolesArray)
  }

  const isChecked = roleObj => {
    if (selectedUser && selectedUser.roles && selectedUser.roles.filter) {
      let tempArr = selectedUser.roles.filter(obj => obj.id_role === roleObj.id_role)
      return tempArr.length > 0 && !tempArr[0].remove
    } else {
      return false
    }
  }

  const [progress, setProgress] = useState(0)

  const [editImage, setEditImage] = useState(false)

  const onDropAction = async (file, idx) => {
    file = file[0]

    try {
      props.onChangeImage(file, setProgress).then(() => {
        setEditImage(false)
      })
    } catch (err) {
      console.error(err)
    }
  }

  const countryOptions = () => {
    let countryArray=[]
    for (let i in countriesList.countries) {
      countryArray.push({ value: countriesList.countries[i].name, label: countriesList.countries[i].name })
    }
    countryArray.sort((a, b) => {
      if (a.value < b.value) {
        return -1
      } else if (a.value > b.value) {
        return 1
      } else {
        return 0
      }
    })
    return countryArray
  }

  return (
    <PerfectScrollbar
      options={{
        suppressScrollX: true,
        useBothWheelAxes: false,
      }}
      className={'height-100p p-4'}
    >
      <div className={'row'}>
        <div
          className={'col-md-12 col-12 mt-3'}
          style={{ display: 'flex', width: '100%', justifyContent: 'center' }}
        >
          <div style={{ height: 170, width: 170 }}>
            {selectedUser.user_image && selectedUser.user_image != '0' && !editImage ? (
              <img
                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                alt="avatar"
                src={
                  'https://storage.googleapis.com/pixaera-files/profile-images/' +
                  selectedUser.user_image
                }
              />
            ) : (
              <CustomDropzone
                onDropAction={onDropAction}
                completed={progress}
                inactiveLabel={'Drag the image'}
                activeLabel={'Drop here'}
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              padding: 5,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #989FAB',
              bottom: 0,
              right: 130,
              backgroundColor: '#FAFAFA',
              borderRadius: '3px',
            }}
          >
            {editImage ? (
              <a
                onClick={() => {
                  setEditImage(false)
                }}
                style={{ fontSize: 16 }}
              >
                <i className="fe fe-x" /> Cancel
              </a>
            ) : (
              <a
                onClick={() => {
                  setEditImage(true)
                }}
                style={{ fontSize: 16 }}
              >
                <i className="fe fe-edit" /> Edit
              </a>
            )}
          </div>
        </div>
        <div className={'col-md-6 col-12 mt-3'}>
          <TextInput
            placeholder="ID"
            value={selectedUser.id_user ? selectedUser.id_user : ''}
            label="ID"
            disabled
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
          <TextInput
            placeholder="Created Date"
            value={moment(selectedUser.created_at).format('DD/MM/YYYY')}
            label="Created Date"
            disabled
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
          <TextInput
            placeholder="First Name"
            value={selectedUser.user_first_name ? selectedUser.user_first_name : ''}
            label="First Name"
            disabled
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
          <TextInput
            placeholder="Last Name"
            value={selectedUser.user_last_name ? selectedUser.user_last_name : ''}
            label="Last Name"
            disabled
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
          <TextInput
            placeholder={`E-mail${selectedUser.user_email_verified ? ' (Verified)' : ''}`}
            value={selectedUser.user_email ? selectedUser.user_email : ''}
            label={`E-mail${selectedUser.user_email_verified ? ' (Verified)' : ''}`}
            disabled={selectedUser.user_email_verified}
            {...props}
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
          <DropdownWithTitle
            title={'Company'}
            value={selectedUser.id_company}
            options={companyOptions}
            onChange={value => {
              onChange('id_company', value)
            }}
            disabled
            {...props}
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
          <TextInput
            placeholder="Position"
            value={selectedUser.user_position ? selectedUser.user_position : ''}
            label="Position"
            onChange={event => {
              onChange('user_position', event.target.value)
            }}
            {...props}
          />
        </div>

        <div className={'col-md-6 col-12 mt-3'}>
        <SelectBox
            options={countryOptions()}
            name="country"
            value={selectedUser.user_country}
            onChange={event => {
              onChange('user_country', event.target.value)
            }}
            label="Country"
          />
        </div>
      </div>
    </PerfectScrollbar>
  )
}

export default EditProfile
