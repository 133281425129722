import React from 'react'
import style from './style.module.scss'

const TextInput = props => {
  return (
    <div>
      <h6 className={`text-uppercase font-size-12 mb-2`}>{props.label}</h6>
      <div className={style.textfield}>
        <input {...props} className={`${style.textfield_input} font-size-14`}/>
      </div>
    </div>
  )
}

export default TextInput
