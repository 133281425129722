import axios from 'axios'

export default class Auth {
  static async me() {
    const { data } = await axios(`${process.env.REACT_APP_API_ADDRESS}/auth/me`, {
      withCredentials: true,
    })
    return data
  }

  static async authUrl() {
    const { data } = await axios(`${process.env.REACT_APP_API_ADDRESS}/auth/url`, {
      withCredentials: true,
    })
    return data
  }

  static async permission(permission) {
    await axios(`${process.env.REACT_APP_API_ADDRESS}/auth/permission/${permission}`, {
      withCredentials: true,
    });
    return;
  }

  static async logout() {
    const response = await axios(`${process.env.REACT_APP_API_ADDRESS}/auth/logout`, {
      withCredentials: true,
    });
    return;
  }

  static async refresh() {
    const { data } = await axios(`${process.env.REACT_APP_API_ADDRESS}/auth/refresh`, {
      withCredentials: true,
    })
    return data
  }
}
