import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import moduleBuildsApi from '../../api/module_builds'

export function* LOAD_MODULE_BUILDS() {
  yield put({
    type: 'module_builds/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let module_builds;
  try {
    module_builds = yield call(moduleBuildsApi.list.bind(moduleBuildsApi))
  } catch (err) {
    console.warn(err);
  }

  if (module_builds) {
    yield put({
      type: 'module_builds/SET_STATE',
      payload: {
        module_builds
      },
    })
  }
  yield put({
    type: 'module_builds/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_MODULE_BUILDS, LOAD_MODULE_BUILDS),
    LOAD_MODULE_BUILDS(), // run once on app load to check user auth
  ])
}
