import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import authApi from '../../api/auth'
import Company from '../../api/Company'
import getMenuData from 'services/menu'

export function* LOAD_CURRENT_ACCOUNT() {
  // Loading State to True
  yield put({type: 'user/SET_STATE',payload: {loading: true}});

  try {
    // Refresh User Information
    yield call(authApi.refresh)

    const user = yield call(authApi.me)

    if(!user.user_global_admin) {
      window.location.href = `${process.env.REACT_APP_LEARN_PLATFORM_ADDRESS}`;
    }

    let company = null
    if (user.id_company) {
      company = yield call(Company.get.bind(Company), user.id_company)
    }

    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...user,
        authorized: true,
        company,
      },
    })

    // Update Menu Data
    let menuData = yield call(getMenuData)
    yield put({type: 'menu/SET_STATE', payload: {menuData}})
  } catch (err) {
    try {
      yield call(authApi.logout)
    } catch(err2) {}
    try {
      const { url } = yield call(authApi.authUrl)
      window.location.href = url
    } catch(err3) {
      console.error(err3);
    }
  }

  // Remove Loading State
  yield put({type: 'user/SET_STATE',payload: {loading: false}});
}

export function* LOGOUT() {
  yield call(authApi.logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id_user: '',
      user_name: '',
      role: '',
      user_email: '',
      user_image: '',
      authorized: process.env.REACT_APP_AUTHENTICATED || false, // false is default value
      loading: false,
      id_company: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user errors
  ])
}
