import authApi from '../../api/auth'

export default async function getMenuData() {
  let menu = [
    {
      title: 'Companies',
      key: 'companies',
      url: '/companies',
      icon: 'fa fa-industry',
      permission: 'companies:read',
    },
    {
      title: 'Modules',
      key: 'modules',
      url: '/modules',
      icon: 'fa fa-puzzle-piece',
      permission: 'modules:read',
    },
    {
      title: 'Users',
      key: 'users',
      url: '/users',
      icon: 'fa fa-users',
      permission: 'users:read',
    },
    {
      title: 'Security',
      permission: 'roles:read',
      icon: 'fa fa-key',
      children: [
        {
          title: 'Permissions',
          key: 'permissions',
          url: '/permissions',
          icon: 'fa fa-lock',
          permission: 'permissions:read',
        },
        {
          title: 'Roles',
          key: 'roles',
          url: '/roles',
          icon: 'fa fa-id-card',
          permission: 'roles:read',
        }
      ]
    },
    {
      title: 'Streaming Regions',
      key: 'streaming_regions',
      url: '/streaming/regions',
      icon: 'fa fa-signal',
      permission: 'streaming:read',
    },
  ];

  let promisesList = [];
  let permissionsList = [];

  for (let i = 0; i < menu.length; i++) {
    let menuItem = menu[i];
    if(menuItem.permission) {
      console.log(permissionsList);
      if(!(permissionsList.indexOf(menuItem.permission) >= 0)) {
        promisesList.push(new Promise(async (resolve, reject) => {
          try {
            await authApi.permission(menuItem.permission);
            return resolve(menuItem.permission);
          } catch(err) {
            return resolve(false);
          }
        }))
        permissionsList.push(menuItem.permission);
      }
    }
  }

  let permissions = await Promise.all(promisesList);

  let newMenu = [];

  for (let i = 0; i < menu.length; i++) {
    let menuItem = menu[i];
    if(!menuItem.permission || permissions.indexOf(menuItem.permission) >= 0) {
      newMenu.push(menuItem);
    }
  }

  return newMenu;
}
