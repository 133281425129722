import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions from './actions'
import permissionsApi from '../../api/permissions'

export function* LOAD_PERMISSIONS() {
  yield put({
    type: 'permissions/SET_STATE',
    payload: {
      loading: true,
    },
  })
  let permissions;
  try {
    permissions = yield call(permissionsApi.list.bind(permissionsApi))
  } catch (err) {
    console.warn(err);
  }

  if (permissions) {
    yield put({
      type: 'permissions/SET_STATE',
      payload: {
        permissions
      },
    })
  }
  yield put({
    type: 'permissions/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PERMISSIONS, LOAD_PERMISSIONS),
    LOAD_PERMISSIONS(), // run once on app load to check user auth
  ])
}
