import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'
import SignInButton from '../../components/pixaera/layout/SignInButton'
import MenuLeft from '../../components/cleanui/layout/Menu/MenuLeft'
import TopBar from '../../components/cleanui/layout/TopBar'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
})

const AuthLayout = ({
  children,
  logo,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  isTopbarFixed,
  isGrayTopbar,
}) => {
  return (
    <Layout>
      <MenuLeft />
      <Layout>
        <Layout.Header
          className={classNames('cui__layout__header', {
            cui__layout__fixedHeader: isTopbarFixed,
            cui__layout__headerGray: isGrayTopbar,
          })}
        >
          <TopBar />
        </Layout.Header>
        <Layout.Content style={{ height: '100%', position: 'relative' }}>
          <div className="cui__utils__content">{children}</div>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
