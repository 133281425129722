import GenericModel from './GenericModel/GenericModel'
import axios from 'axios'
import Utils from "../helpers/utils";

const API = '/users'

export default class User extends GenericModel {
  API = API
  static API = API

  static sendImageFile = async (obj, id_user, progressFunction) => {
    try {
      let formData = new FormData()
      formData.append(
        'file',
        obj,
        new Date().getTime() +
          '-' +
          Math.round(Math.random() * 100000) +
          '.' +
          obj.name.split('.').pop(),
      )

      let headerObj = await User.insertAuthHeader()

      let response = await axios.request({
        url: `${process.env.REACT_APP_API_ADDRESS}${this.API}/send-image-file/` + id_user,
        method: 'post',
        data: formData,
        headers: headerObj,
        onUploadProgress: p => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total))
          }
        },
      })
      return response.data
    } catch (err) {
      throw Error(err)
    }
  }

  // READ ENTITY (LIST)
  static async listEvents(id_user) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}${this.API}/${id_user}/events`,
      {
        withCredentials: true,
      },
    )
    return data
  }

}
