import store from 'store'
import actions from './actions'

const STORED_SETTINGS = storedSettings => {
  const companies = {}
  Object.keys(storedSettings).forEach(key => {
    const item = store.get(`app.simulations.${key}`)
    companies[key] = typeof item !== 'undefined' ? item : storedSettings[key]
  })
  return companies
}

const initialState = {
  ...STORED_SETTINGS({
    companies: [],
  }),
}

export default function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
