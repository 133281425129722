import GenericModel from './GenericModel/GenericModel';
import axios from 'axios';

const API = '/modules';

export default class Module extends GenericModel {
  API = API
  static API = API

  static sendImageFile = async (obj, progressFunction) => {
    try {
      let formData = new FormData()
      formData.append('file', obj,
        new Date().getTime() +
        '-' +
        Math.round(Math.random() * 100000) +
        '.' +
        obj.name.split('.').pop(),
      )

      let headerObj = await Module.insertAuthHeader()

      let response = await axios.request({
        url: `${process.env.REACT_APP_API_ADDRESS}${this.API}/send-image-file`,
        method: 'post',
        data: formData,
        headers: headerObj,
        onUploadProgress: p => {
          if (progressFunction) {
            progressFunction(Math.round((p.loaded * 100) / p.total))
          }
        },
      })
      return response.data
    } catch (err) {
      throw Error(err)
    }
  }
}
