import React, { Component } from 'react'
import style from './style.module.scss'

const SelectBox = props => {
  const { options, value, name } = props
  return (
    <div>
      <h6 className={`text-uppercase font-size-12 mb-2`}>{props.label}</h6>
      <select
        name={name}
        className={`${style.selector}`}
        disabled={props.disabled ? props.disabled : false}
        onChange={props.onChange}
        value={value}
      >
        {options.map((option, idx) => {
          return (
            <option key={idx} className={`${style.selector_option}`} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export default SelectBox
