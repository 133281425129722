import Utils from '../../helpers/utils'
import axios from 'axios'
import authApi from '../auth'

class GenericModel {
  constructor(object, API) {
    for (let prop in object) {
      if (object.hasOwnProperty(prop)) {
        this[prop] = object[prop]
      }
    }
    this.API = API
  }

  static checkGlobalUser = async () => {
    try {
      let me = await authApi.me()
      if (me.user_global_admin) {
        return true
      } else {
        throw new Error('You are not a Global User')
      }
    } catch (err) {
      throw err
    }
  }

  static insertAuthHeader = async headerObj => {
    if (!headerObj) {
      headerObj = { 'Content-type': 'application/json' }
    }
    // const id_app = localStorage.getItem('ID_APP');
    try {
      // if (id_app) headerObj['id_app'] = id_app;
      return headerObj
    } catch (err) {
      throw Error(err)
    }
  }

  // CREATE ENTITY
  static async create(object) {
    const { data } = await axios.post(`${process.env.REACT_APP_API_ADDRESS}${this.API}`, object, {
      withCredentials: true,
    })
    return data
  }

  // READ ENTITY (GET)
  static async get(id) {
    const { data } = await axios.get(`${process.env.REACT_APP_API_ADDRESS}${this.API}/${id}`, {
      withCredentials: true,
    })
    return data
  }

  // READ ENTITY (LIST)
  static async list(query) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_ADDRESS}${this.API}?${Utils.serialize(query)}`,
      {
        withCredentials: true,
      },
    )
    return data
  }
  // UPDATE ENTITY
  static async update(id, object) {
    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_ADDRESS}${this.API}/${id}`,
      object,
      {
        withCredentials: true,
      },
    )
    return data
  }

  // DEPRECATED - Save GenericModel object
  save = async function() {
    let that = this

    let authHeader = {}

    try {
      authHeader = await GenericModel.insertAuthHeader({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      })
      let API = this.API
      delete this.API
      let response = await fetch(`${process.env.REACT_APP_API_ADDRESS}${API}`, {
        method: 'POST',
        headers: authHeader,
        body: JSON.stringify(this),
        credentials: 'include',
      })

      if (response.status >= 400) {
        throw Error(response.statusText)
      }

      let object = await response.json()

      return new GenericModel(object, that.API)
    } catch (err) {
      console.error(err)
      return new Error(err)
    }
  }

  // DELETE ENTITY
  static async delete(id) {
    const { data } = await axios.delete(`${process.env.REACT_APP_API_ADDRESS}${this.API}/${id}`, {
      withCredentials: true,
    })
    return data
  }

  // DELETE ENTITY
  static async remove(id) {
    const { data } = await axios.delete(`${process.env.REACT_APP_API_ADDRESS}${this.API}/${id}`, {
      withCredentials: true,
    })
    return data
  }
}

export default GenericModel
