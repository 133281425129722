import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {Progress} from "antd";

export function CustomDropzone(props) {

  const onDrop = useCallback(acceptedFiles => {

    return props.onDropAction(acceptedFiles);

  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  const inactiveLabel = props.inactiveLabel ? props.inactiveLabel : 'Arraste o cupom para cá!';
  const activeLabel = props.activeLabel ? props.activeLabel : 'Solte o cupom!';

  return (
    <div style={{
      maxHeight: '100%',
      minHeight: '100px',
      border: '5px dashed #bdbdbd',
      margin: '20px',
      maxWidth:"100%",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }} {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        {!props.completed && !isDragActive && inactiveLabel}
        {!props.completed && isDragActive && activeLabel}
        {props.completed > 0 && <Progress type="circle" percent={props.completed*100}/>}
      </div>
    </div>
  )
}