import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge, Modal } from 'antd'
import styles from './style.module.scss'
import SignInButton from '../../../../pixaera/layout/SignInButton'
import User from '../../../../../api/User'
import EditProfile from '../../../../pixaera/layout/users/EditProfile'
import axios from 'axios'

const mapStateToProps = ({ user, companies, settings, roles }) => ({
  user,
  settings,
  roles: roles.roles,
  companies: companies.companies,
})

const ProfileMenu = ({ dispatch, user, companies, settings, roles }) => {
  const [count, setCount] = useState(7)

  const logout = e => {
    e.preventDefault()
    window.location.href = `${process.env.REACT_APP_API_ADDRESS}/auth/logout`
  }

  const addCount = () => {
    setCount(count + 1)
  }

  //MODAL FUNCTIONS
  const [stateUser, setUser] = useState(user)

  const [visible, setVisible] = useState(false)

  const companyOptions = companies
    .map(obj => ({ label: obj.company_name, value: obj.id_company }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const onChangeImage = (file, onProgressFunction) => {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await User.sendImageFile(file, stateUser.id_user, onProgressFunction)
        setUser({ ...stateUser, user_image: response.filename })
        return resolve()
      } catch (error) {
        return
      }
    })
  }

  if (settings.img_error) {
    axios
      .get('https://storage.googleapis.com/pixaera-files/profile-images/' + user.user_image)
      .then(() => {
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: { setting: 'img_error', value: false },
        })
      })
      .catch(error =>
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: { setting: 'img_error', value: true },
        }),
      )
  }

  let result
  const menu = (
    <Menu selectable={false}>
      <Modal
        visible={visible}
        onOk={() => {
          return new Promise(async (resolve, reject) => {
            try {
              delete stateUser.user_image
              await User.update(stateUser.id_user, stateUser)
              dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT', payload: null })
              setVisible(false)
              return resolve()
            } catch (e) {
              setVisible(false)
              return reject(e)
            }
          })
        }}
        onCancel={() => {
          dispatch({ type: 'user/LOAD_CURRENT_ACCOUNT', payload: null })
          setUser(user)
          setVisible(false)
        }}
      >
        <EditProfile
          onChange={(parameter, value) => {
            setUser({
              ...stateUser,
              [parameter]: value,
            })
          }}
          roles={roles}
          companyOptions={companyOptions}
          user={stateUser}
          onChangeImage={onChangeImage}
        />
      </Modal>
      <Menu.Item>
        <strong>
          <FormattedMessage id="topBar.profileMenu.hello" />, {user.user_name || 'Anonymous'}
        </strong>
        <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
          </strong>
          Professional
        </div>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.company" />:{' '}
          </strong>
          {user && user.company ? user.company.company_name : '—'}
        </div>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.role" />:{' '}
          </strong>
          {user.role || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.user_email || '—'}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user.user_phone || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a
          onClick={e => {
            e.preventDefault()
            setVisible(true)
          }}
        >
          <i className="fe fe-user mr-2" />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  if (user.authorized) {
    result = (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Badge>
            <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
          </Badge>
        </div>
      </Dropdown>
    )
  } else {
    result = <SignInButton />
  }

  return result
}

export default connect(mapStateToProps)(ProfileMenu)
